<template>
    <div class="relative flex gap-x-3">
        <div class="flex h-6 items-center">
            <input
                v-model="value"
                :name="name"
                :id="name"
                type="checkbox"
                class="h-4 w-4 rounded-xl border-gray-300 text-primary focus:ring-primary"
            />
        </div>
        <div class="text-md leading-6">
            <label :for="name" class="font-medium text-md text-gray-900">{{ label }}</label>
            <p class="text-gray-500 text-sm leading-relaxed">
                <slot></slot>
            </p>
            <span class="mt-2 text-sm text-error">
                {{ errorMessage }}
            </span>
        </div>
    </div>
</template>

<script setup>
import { useField } from "vee-validate";

const props = defineProps({
    name: String,
    label: String,
});

// The `name` is returned in a function because we want to make sure it stays reactive
// If the name changes you want `useField` to be able to pick it up
const { value, errorMessage } = useField(() => props.name);
</script>
