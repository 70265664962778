<template>
    <label :for="name" class="block text-md font-medium leading-6 text-richDark-900">{{ label }}</label>
    <div class="mt-2">
        <input
            v-model="value"
            :name="name"
            :id="name"
            :type="type || 'text'"
            :autocomplete="autocomplete"
            class="block w-full rounded-full border-2 py-2 px-4 text-richDark-900 focus:border-4 focus:border-primary sm:text-md sm:leading-6"
        />
        <div class="min-h-6"><span class="mt-1 text-sm text-error">
            {{ errorMessage }}
        </span></div>
    </div>
</template>

<script setup>
import { useField } from "vee-validate";

const props = defineProps({
    name: { type: String, required: true },
    type: { type: String, required: true },
    autocomplete: String,
    label: { type: String, required: true },
});

// The `name` is returned in a function because we want to make sure it stays reactive
// If the name changes you want `useField` to be able to pick it up

const { value, errorMessage } = useField(() => props.name, undefined, {
    syncVModel: true,
});
</script>
