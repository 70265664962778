<template>
    <svg
        xmlns:svg="http://www.w3.org/2000/svg"
        xmlns="http://www.w3.org/2000/svg"
        version="1.1"
        x="0px"
        y="0px"
        viewBox="0 0 750 500"
        width="750"
    >
        <g transform="translate(115.5,-98.2)" class="global">
            <path d="m 267.5,310.4 c -0.7,4.8 -4.2,3.7 -5.7,2.4 -1.5,-1.3 -1.4,-5.6 -0.5,-7.7 0.9,-2.1 4.5,-1.9 4.7,-1.6" />
            <path
                d="m 106,400 c 0,0 0,-23.8 0,-63.2 0,-39.4 -39.3,-37.3 -39.3,-37.3 h -24.7 c 0,0 -39.3,2.1 -39.3,-37.3 0,-39.4 0,-63.2 0,-63.2"
            />
            <line y2="300" x2="106" y1="199" x1="106" />
            <line y2="400" x2="2.3" y1="300" x1="2.3" />
            <path
                d="m 517,400 c 0,0 0,-23.8 0,-63.2 0,-39.4 -39.3,-37.3 -39.3,-37.3 h -24.7 c 0,0 -39.3,2.1 -39.3,-37.3 0,-39.4 0,-63.2 0,-63.2"
            />
            <line y2="300" x2="517" y1="199" x1="517" />
            <path
                d="m 221,323.24 c -2.6,3.2 -11.2,13.5 -22.4,25.5 v 23.6 28.7 h 64.2 c 0,0 58.2,3.4 58.2,-63.2 v -29.7 c 0,0 0,20.3 0,-46.3 0,-66.6 -58.2,-63.2 -58.2,-63.2 h -64.2 v 34.2 31.8 c 10.6,-6.8 24.7,-12 37.5,-4.8 24.6,14 18.5,24.1 18,27.8 -0.5,3.7 -3.3,5.1 -0.3,7.5 3,2.4 11.9,5.9 12.4,8.3 0.5,2.4 4,11.7 -2.2,16.9 -6.2,5.2 -25.9,15.1 -30.7,14.5 -4.8,-0.6 -13.6,-12.5 -14.4,-16 -0.4,-1.6 -0.7,-3.6 -1,-5.5 0,0 -0.2,-3.5 0,-3.8"
            />
            <path d="M 173.305,338.04" />
            <path
                d="m 232.81,281.84 c 0,0 -2.4,14.7 -7.9,20.1 -5.5,5.4 -7.7,9.7 -10.1,10.6 -2.4,0.9 -8.2,-4.5 -8.9,-11.2 -0.7,-6.7 -1.4,-12.5 -1.4,-12.5"
            />
            <circle r="3.1" cy="291.84" cx="240.21" />
        </g>
    </svg>
</template>

<style scoped>
.global {
    stroke: #000000;
    stroke-width: 8;
    stroke-linecap: round;
    stroke-opacity: 1;
    fill: none;
    stroke-linejoin: round;
    stroke-miterlimit: 10;
}
</style>
