<template>
    <div class="mx-auto max-w-7xl px-4 py-4 sm:px-6 sm:py-8 lg:px-8">
        <div class="mx-auto max-w-2xl flex flex-col items-center">
            <HDYLogo class="max-w-sm" />
            <form @submit="submitForm">
                <div class="space-y-10">
                    <h1 class="text-2xl font-semibold text-richDark-900">HDY New Client Registration</h1>

                    <div class="mt-10 grid grid-cols-1 gap-x-6 gap-y-6 sm:grid-cols-6">
                        <div class="sm:col-span-6">
                            <HDYInputText name="username" type="email" autocomplete="email" label="Email address" />
                        </div>

                        <div class="sm:col-span-3">
                            <HDYInputText name="firstname" type="text" autocomplete="given-name" label="First name" />
                        </div>

                        <div class="sm:col-span-3">
                            <HDYInputText name="lastname" type="text" autocomplete="family-name" label="Last name" />
                        </div>

                        <div class="sm:col-span-6">
                            <HDYInputText name="mobile" type="tel" autocomplete="mobile tel-national" label="Mobile Phone" />
                        </div>

                        <div class="sm:col-span-6">
                            <HDYInputCheckbox name="subscribed" label="Receive occasional email updates"
                                >We update our community around once a month on what is happening at the studio, upcoming events,
                                special offers and workshops.</HDYInputCheckbox
                            >
                        </div>
                        <div class="sm:col-span-6">
                            <HDYInputCheckbox name="liabilityReleased" label="Agree to the liability release below:">
                                I agree that Hot Dog Yoga Pty Ltd, its owners, instructors and any person employed by Hot Dog Yoga
                                Pty Ltd is in no way responsible for the safekeeping of my personal belongings. I understand that
                                classes at Hot Dog Yoga may be physically strenuous, and performed in a heated room, and I
                                voluntarily participate in them with full knowledge that there is risk of personal injury,
                                property loss or death. I agree that neither I, my heirs, assigns or legal representatives will
                                sue or make any other claims of any kind whatsoever against Hot Dog Yoga or its members for any
                                personal injury, property damage/loss, or wrongful death, whether caused by negligence or
                                otherwise.</HDYInputCheckbox
                            >
                        </div>
                    </div>
                </div>

                <div class="mt-6 flex items-center justify-end gap-x-6">
                    <button
                        type="submit"
                        class="rounded-full bg-primary px-12 py-3 text-lg font-semibold text-white shadow-sm hover:bg-secondary disabled:bg-offWhite focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-primary"
                    >
                        Register
                    </button>
                </div>
            </form>
        </div>
    </div>
</template>

<script setup>
import { useForm, useSubmitForm, configure } from "vee-validate";
import { toTypedSchema } from "@vee-validate/yup";
import * as yup from "yup";

useHead({
    title: "Registration",
});

const hdyApi = useApi();
const isAuMobilePhone = (phoneNumber) => isMobilePhone(phoneNumber, "en-AU");
const stripWhitespace = (str) => str.replace(/\s+/g, "");

configure({ bails: true });

const userSchema = toTypedSchema(
    yup.object({
        username: yup.string().required("Email is required").email("Please enter a valid email address"),
        firstname: yup.string().required("First name is a required field"),
        lastname: yup.string().required("Last name is a required field"),
        mobile: yup
            .string()
            .transform((value, originalValue) => stripWhitespace(value))
            .required("Mobile is a required field"),
        subscribed: yup.boolean().default(true),
        liabilityReleased: yup.boolean().test({
            name: "isLiabilityReleased",
            test: (val) => val,
            message: "You must accept the liability release to register",
        }),
    }),
    { abortEarly: false }
);

const { meta, values, errors, handleSubmit } = useForm({ validationSchema: userSchema });

const isFormValid = computed(() => meta.value.dirty && meta.value.valid);

const submitForm = handleSubmit(
    async (values, actions) => {
        const config = useRuntimeConfig();
        const { $toast, $spinner } = useNuxtApp();

        const loader = $spinner.show();
        try {
            const hasAccount = await $fetch("clients/has-account", {
                method: "POST",
                baseURL: config.public.apiBase,
                mode: "cors",
                body: { username: values.username },
            });

            if (hasAccount.data.hasAccount) {
                const errorMessage = "There is already another account with this username";
                actions.setFieldError("username", errorMessage);
                scrollToFirstError({ username: errorMessage });
                return;
            }

            // Ready to submit
            const submitResult = await $fetch("clients", {
                method: "POST",
                baseURL: config.public.apiBase,
                mode: "cors",
                body: values,
            });

            if (submitResult?.data?.client?.Id) {
                // Check if we got a client back
                // success
                await navigateTo("/complete");
            } else {
                return;
            }
            console.log(submitResult);
        } catch (e) {
            $toast.error(JSON.stringify(e.message, undefined, 2), { autoClose: false });

            console.log(e);
        } finally {
            loader.hide();
        }
    },
    ({ errors }) => scrollToFirstError(errors)
);
</script>
